/*only animation declarations*/
@keyframes image-rotate {
  0% {
    opacity: 1;
  }

  33% {
    opacity: 1;
  }

  43% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    transform: scale3d(1, 1, 1);
  }
  25% { transform: scale3d(1.15, 1.15, 1.15) }
  50% { transform: scale3d(1, 1, 1) }
  70% {
    box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

@keyframes pulse_animation {
	0% { transform: scale(1); }
	30% { transform: scale(1); }
	40% { transform: scale(1.08); }
	50% { transform: scale(1); }
	60% { transform: scale(1); }
	70% { transform: scale(1.05); }
	80% { transform: scale(1); }
	100% { transform: scale(1); }
}

