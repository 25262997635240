/* global styles go here, use sparingly */
* {
  box-sizing: border-box;
}

html {
  background-color: #eee;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* make the table filter row a bit smaller */
.-filters {
  height: 54px;
}

#intercom-container .intercom-launcher-frame {
  z-index: 1201;
}
